// UserProfile Component
import React from "react";
import styles from "./UserProfile.module.css";

interface UserProfileProps {}

const UserProfile: React.FC<UserProfileProps> = () => {
  return (
    <div className={styles.profileContainer}>
      <h2 className={styles.profileTitle}>Meu Perfil</h2>
      <div className={styles.profileDetails}>
        <label className={styles.profileLabel}>Nome:</label>
        <p className={styles.profileText}>Usuário Exemplo</p>

        <label className={styles.profileLabel}>Email:</label>
        <p className={styles.profileText}>usuario@example.com</p>

        <label className={styles.profileLabel}>Próximas Sessões:</label>
        <ul className={styles.profileSessions}>
          <li className={styles.sessionItem}>
            Sessão com Dr. João - 20 de Agosto, 15h
          </li>
          <li className={styles.sessionItem}>
            Sessão com Dra. Maria - 25 de Agosto, 10h
          </li>
        </ul>
      </div>
      <button className={styles.profileButton}>Editar Perfil</button>
    </div>
  );
};

export default UserProfile;
