// Como Funciona Component atualizado para usar o novo style
import React from "react";
import styles from "../styles/main_content.module.css"; // Importando o CSS Module

const ComoFunciona: React.FC = () => {
  return (
    <div className={styles.mainContainer}>
      <h2 className={styles.mainTitle}>Como Funciona</h2>
      <ol className={styles.mainText}>
        <li>Escolha o seu psicólogo.</li>
        <li>Agende uma sessão online.</li>
        <li>Receba atendimento personalizado.</li>
      </ol>
    </div>
  );
};

export default ComoFunciona;
