import React from "react";
import styles from "./Footer.module.css"; // Importando o CSS Module

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <p>
        &copy; {new Date().getFullYear()} Equilibria. Todos os direitos
        reservados.
      </p>
      <div className={styles.socialLinks}>
        <a
          href="https://facebook.com"
          className={styles.socialLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <a
          href="https://twitter.com"
          className={styles.socialLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
        <a
          href="https://instagram.com"
          className={styles.socialLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
      </div>
    </footer>
  );
};

export default Footer;
