// Booking Component
import React, { useState } from "react";
import styles from "./Booking.module.css"; // Importando o CSS Module

interface BookingProps {}

const Booking: React.FC<BookingProps> = () => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTime(event.target.value);
  };

  const handleBooking = (event: React.FormEvent) => {
    event.preventDefault();
    if (selectedDate && selectedTime) {
      alert(`Sessão agendada para ${selectedDate} às ${selectedTime}`);
    } else {
      alert("Por favor, selecione uma data e um horário.");
    }
  };

  return (
    <div className={styles.bookingContainer}>
      <h2 className={styles.bookingTitle}>Agendar Sessão</h2>
      <form onSubmit={handleBooking} className={styles.bookingForm}>
        <label className={styles.bookingLabel}>Selecione uma data:</label>
        <input
          type="date"
          value={selectedDate || ""}
          onChange={handleDateChange}
          className={styles.bookingInput}
          required
        />

        <label className={styles.bookingLabel}>Selecione um horário:</label>
        <select
          value={selectedTime || ""}
          onChange={handleTimeChange}
          className={styles.bookingSelect}
          required
        >
          <option value="" disabled>
            Selecione um horário
          </option>
          <option value="09:00">09:00</option>
          <option value="11:00">11:00</option>
          <option value="14:00">14:00</option>
          <option value="16:00">16:00</option>
        </select>

        <button type="submit" className={styles.bookingButton}>
          Agendar
        </button>
      </form>
    </div>
  );
};

export default Booking;
