// PsychologistProfile Page
import React from "react";
import PsychologistProfile from "../components/PsychologistProfile";
import { useParams } from "react-router-dom";

const PsychologistProfilePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  // Dados fictícios do psicólogo (em uma aplicação real, seria buscado de uma API com base no ID)
  const psychologistData = {
    name: "Dra. Maria Silva",
    specialty: "Terapia Cognitivo-Comportamental",
    description:
      "Dra. Maria Silva é especialista em Terapia Cognitivo-Comportamental com mais de 10 anos de experiência ajudando pacientes a superar desafios emocionais e comportamentais.",
  };

  return (
    <div>
      <PsychologistProfile
        name={psychologistData.name}
        specialty={psychologistData.specialty}
        description={psychologistData.description}
      />
    </div>
  );
};

export default PsychologistProfilePage;
