// Psicólogos Component atualizado para usar o novo style
import React from "react";
import styles from "../styles/main_content.module.css"; // Importando o CSS Module

interface Psychologist {
  name: string;
  specialty: string;
}

const Psychologists: React.FC = () => {
  const psychologists: Psychologist[] = [
    { name: "Dr. João", specialty: "Terapia Cognitivo-Comportamental" },
    { name: "Dra. Maria", specialty: "Psicologia Infantil" },
    { name: "Dr. Pedro", specialty: "Psicologia Clínica" },
  ];

  return (
    <div className={styles.mainContainer}>
      <h2 className={styles.mainTitle}>Nossos Psicólogos</h2>
      <ul className={styles.mainText}>
        {psychologists.map((psychologist, index) => (
          <li key={index}>
            {psychologist.name} - {psychologist.specialty}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Psychologists;
