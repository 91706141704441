import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import HowItWorksPage from "./pages/HowItWorksPage";
import PsychologistsPage from "./pages/PsychologistsPage";
import NotFoundPage from "./pages/NotFoundPage"; // Importa a página de erro 404
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import UserProfilePage from "./pages/UserProfilePage";
import BookingPage from "./pages/BookingPage";
import PsychologistProfilePage from "./pages/PsychologistProfilePage";
import TestimonialsPage from "./pages/TestimonialsPage";
import VideoSessionPage from "./pages/VideoSessionPage";
import ContactPage from "./pages/ContactPage";
// Definindo a interface para as props do App (vazia, pois App não usa props)
interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/how-it-works" element={<HowItWorksPage />} />
        <Route path="/psychologists" element={<PsychologistsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/profile" element={<UserProfilePage />} />
        <Route path="/booking" element={<BookingPage />} />
        <Route path="/psychologist/:id" element={<PsychologistProfilePage />} />
        <Route path="/testimonials" element={<TestimonialsPage />} />
        <Route path="/video-session" element={<VideoSessionPage />} />
        <Route path="*" element={<NotFoundPage />} />{" "}
        {/* Rota genérica para erro 404 */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
