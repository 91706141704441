import React from "react";
import ReactDOM from "react-dom/client";
import "normalize.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/base_styles.css";

// Utilizando TypeScript para definir o tipo do elemento HTML
const rootElement = document.getElementById("root") as HTMLElement;

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// Função opcional para medir performance
// Se você quiser começar a medir a performance da sua aplicação, pode passar uma função para registrar os resultados
// (por exemplo: reportWebVitals(console.log)) ou enviar para algum endpoint de analytics.
// Saiba mais: https://bit.ly/CRA-vitals
reportWebVitals();
