import React from "react";
import { Link } from "react-router-dom";

// Definindo a interface para as props (vazia, pois a página não recebe props)
interface NotFoundPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  return (
    <div>
      <h1>404</h1>
      <h2>Página não encontrada</h2>
      <p>Desculpe, a página que você está procurando não existe.</p>
      <Link to="/">
        <button>Voltar para a Página Inicial</button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
