import React from "react";
import styles from "../styles/main_content.module.css";

const HomePage: React.FC = () => {
  return (
    <div className={styles.mainContainer}>
      <h1 className={styles.mainTitle}>Bem-vindo ao Equilibria</h1>
      <p className={styles.mainText}>
        Aqui você encontra apoio psicológico de qualidade. Nossa plataforma
        oferece um ambiente seguro e acolhedor para cuidar da sua saúde mental.
      </p>
      <button className={styles.mainButton}>Saiba Mais</button>
    </div>
  );
};

export default HomePage;
