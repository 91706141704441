// Testimonials Page
import React from "react";
import Testimonials from "../components/Testimonials";

const TestimonialsPage: React.FC = () => {
  return (
    <div>
      <Testimonials />
    </div>
  );
};

export default TestimonialsPage;
