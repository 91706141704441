// Testimonials Component
import React from "react";
import styles from "./Testimonials.module.css"; // Importando o CSS Module

interface Testimonial {
  name: string;
  content: string;
}

const Testimonials: React.FC = () => {
  const testimonials: Testimonial[] = [
    {
      name: "Ana Souza",
      content:
        "A Equilibria mudou a minha vida. O suporte psicológico foi essencial para meu bem-estar.",
    },
    {
      name: "Carlos Pereira",
      content:
        "Excelente plataforma, fácil de usar e com profissionais muito qualificados.",
    },
    {
      name: "Mariana Lima",
      content:
        "Minha experiência com a Equilibria foi incrível. Recomendo a todos!",
    },
  ];

  return (
    <div className={styles.testimonialsContainer}>
      <h2 className={styles.testimonialsTitle}>Depoimentos</h2>
      <ul className={styles.testimonialsList}>
        {testimonials.map((testimonial, index) => (
          <li key={index} className={styles.testimonialItem}>
            <p className={styles.testimonialContent}>
              &quot;{testimonial.content}&quot;
            </p>
            <p className={styles.testimonialAuthor}>- {testimonial.name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Testimonials;
