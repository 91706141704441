// ContactForm Component
import React, { useState } from "react";
import styles from "./ContactForm.module.css"; // Importando o CSS Module

const ContactForm: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Aqui, você pode implementar a lógica para enviar o formulário, por exemplo, via API.
    alert(
      `Obrigado por entrar em contato, ${name}! Entraremos em contato em breve.`
    );
  };

  return (
    <div className={styles.contactFormContainer}>
      <h2 className={styles.contactFormTitle}>Entre em Contato</h2>
      <form onSubmit={handleSubmit} className={styles.contactForm}>
        <label className={styles.contactFormLabel}>Nome:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={styles.contactFormInput}
          required
        />

        <label className={styles.contactFormLabel}>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.contactFormInput}
          required
        />

        <label className={styles.contactFormLabel}>Mensagem:</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className={styles.contactFormTextarea}
          required
        />

        <button type="submit" className={styles.contactFormButton}>
          Enviar
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
