// VideoSession Page
import React from "react";
import VideoSession from "../components/VideoSession";

const VideoSessionPage: React.FC = () => {
  return (
    <div>
      <VideoSession />
    </div>
  );
};

export default VideoSessionPage;
