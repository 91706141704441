// VideoSession Component
import React, { useEffect, useRef } from "react";
import styles from "./VideoSession.module.css"; // Importando o CSS Module

interface VideoSessionProps {}

const VideoSession: React.FC<VideoSessionProps> = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    // Esta é uma simulação de inicialização de uma sessão de vídeo.
    // Aqui poderia ser a integração com uma API como WebRTC, Zoom, etc.
    if (videoRef.current) {
      videoRef.current.srcObject = null; // Colocar a stream de vídeo real aqui
    }
  }, []);

  return (
    <div className={styles.videoSessionContainer}>
      <h2 className={styles.videoSessionTitle}>Sessão de Videoconferência</h2>
      <video
        ref={videoRef}
        className={styles.videoElement}
        autoPlay
        playsInline
        controls
      ></video>
      <div className={styles.controlsContainer}>
        <button className={styles.controlButton}>Microfone On/Off</button>
        <button className={styles.controlButton}>Câmera On/Off</button>
        <button className={styles.controlButton}>Encerrar Sessão</button>
      </div>
    </div>
  );
};

export default VideoSession;
