// PsychologistProfile Component
import React from "react";
import styles from "./PsychologistProfile.module.css"; // Importando o CSS Module

interface PsychologistProfileProps {
  name: string;
  specialty: string;
  description: string;
}

const PsychologistProfile: React.FC<PsychologistProfileProps> = ({
  name,
  specialty,
  description,
}) => {
  return (
    <div className={styles.profileContainer}>
      <h2 className={styles.profileTitle}>{name}</h2>
      <p className={styles.profileSpecialty}>{specialty}</p>
      <p className={styles.profileDescription}>{description}</p>
      <button className={styles.profileButton}>Agendar Sessão</button>
    </div>
  );
};

export default PsychologistProfile;
