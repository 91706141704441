// Booking Page
import React from "react";
import Booking from "../components/Booking";

const BookingPage: React.FC = () => {
  return (
    <div>
      <Booking />
    </div>
  );
};

export default BookingPage;
