import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css"; // Importando o CSS Module

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <ul>
          <li>
            <Link to="/" className={styles.link}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/how-it-works" className={styles.link}>
              Como Funciona
            </Link>
          </li>
          <li>
            <Link to="/psychologists" className={styles.link}>
              Psicólogos
            </Link>
          </li>
          <li>
            <Link to="/testimonials" className={styles.link}>
              Depoimentos
            </Link>
          </li>
          <li>
            <Link to="/contact" className={styles.link}>
              Contato
            </Link>
          </li>
        </ul>
      </nav>
      <div className={styles.authButtons}>
        <Link to="/signin">
          <button className={styles.authButton}>Entrar</button>
        </Link>
        <Link to="/signup">
          <button className={styles.authButton}>Cadastre-se</button>
        </Link>
      </div>
    </header>
  );
};

export default Header;
