import React, { useState } from "react";
import styles from "../styles/main_content.module.css"; // Importando o CSS Module

const SignIn: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Lógica de autenticação
  };

  return (
    <div className={styles.mainContainer}>
      <h2 className={styles.mainTitle}>Entrar</h2>
      <form onSubmit={handleSubmit} className={styles.mainText}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Senha"
          required
        />
        <button type="submit" className={styles.mainButton}>
          Entrar
        </button>
      </form>
    </div>
  );
};

export default SignIn;
